import React from 'react'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { cover } from 'polished'

import {
  Box,
  DisplayText,
  FlexCol,
  FlexGrid,
  FlexRow,
  H2,
  H3,
  H6,
  Highlight,
  SEO,
  Span,
  Text,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

import { CustomList } from './_style'

const Founder = props => (
  <Box>
    <FlexGrid gutterX={[3, null, 4]} gutterY={[2]}>
      <Box width={[1, null, 1 / 2]}>
        <Img fluid={props.image} />
      </Box>
      <Box width={[1, null, 1 / 2]}>
        <H3 children={props.heading} mb="0.5em" />
        <H6 children={props.position} mb="1em" />
        <Text>{props.children}</Text>
      </Box>
    </FlexGrid>
  </Box>
)

const Staff = props => (
  <Box width={[1, null, 1 / 3]}>
    {props.image && <Img fluid={props.image} />}
    <H3 children={props.heading} mt={[2, 3]} mb="0.5em" />
    <H6 children={props.position} mb="1em" />
    <Text>{props.children}</Text>
  </Box>
)

const Volunteer = props => (
  <Box width={[1 / 2, null, 1 / 3]}>
    <FlexRow alignItems="center">
      <Box
        width={[64, 96, 128]}
        css={`
          flex-shrink: 0;
        `}
      >
        <Box
          position="relative"
          pb="100%"
          bg="bg.alt"
          borderRadius={9999}
          style={{ overflow: 'hidden' }}
        >
          {props.image && (
            <Img
              fluid={props.image}
              style={{
                ...cover(),
                width: '100%',
                height: '100%',
                userSelect: 'none',
              }}
            />
          )}
        </Box>
      </Box>
      <H3 children={props.children} ml={[2, 3]} />
    </FlexRow>
  </Box>
)

const Funder = props => (
  <li>
    <div>
      <Span
        children={props.children}
        as="a"
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  </li>
)

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        team: prismicTeamPage {
          dataString
          data {
            directors {
              director_image {
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            volunteers {
              volunteer_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      if (!data.team) {
        return null
      }
      let page = JSON.parse(data.team.dataString)
      return (
        <>
          <SEO
            title={
              page.opengraph_title ? RichText.asText(page.opengraph_title) : ''
            }
            description={
              page.opengraph_description
                ? RichText.asText(page.opengraph_description)
                : ''
            }
            image={page.opengraph_image ? page.opengraph_image.url : ''}
          />
          <Box px={[3, null, null, 4]} py={4} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <FlexCol alignItems="center">
                <DisplayText
                  mb="0.5em"
                  textAlign="center"
                  style={{ transform: 'rotate(-7.5deg)' }}
                >
                  <Highlight variant={0}>
                    {RichText.asText(page.hero_header)}
                  </Highlight>
                </DisplayText>
                <Box maxWidth={512} mx="auto">
                  <H3
                    children={`“` + RichText.asText(page.hero_quote) + `“`}
                    mb="1em"
                    textAlign="center"
                    style={{ hangingPunctuation: 'first' }}
                  />
                  <H6
                    children={RichText.asText(page.hero_quote_author)}
                    textAlign="center"
                  />
                </Box>
              </FlexCol>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} py={4} bg="bg.wash">
            <Box maxWidth={1280} mx="auto">
              <FlexGrid gutterX={[3, null, 4, 5]} gutterY={[2]}>
                <Box width={[1, null, 1 / 2]}>
                  <H2 children={RichText.asText(page.introduction_header)} />
                </Box>
                <Box width={[1, null, 1 / 2]}>
                  {RichText.render(
                    page.introduction_body,
                    linkResolver,
                    serializer
                  )}
                </Box>
              </FlexGrid>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} py={[4, 5]} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <H2
                children={RichText.asText(page.directors_header)}
                mb="2em"
                textAlign="center"
              />
              <FlexGrid gutterY={[3, 4]}>
                {page.directors.map((director, index) => {
                  if (director.founder === 'Yes') {
                    return (
                      <Founder
                        heading={director.director_name}
                        position={`Founder, Director`}
                        children={RichText.render(
                          director.director_bio,
                          linkResolver,
                          serializer
                        )}
                        image={
                          data.team.data.directors[index].director_image.fluid
                        }
                      />
                    )
                  } else {
                    return null
                  }
                })}
                <Box>
                  <FlexGrid gutterX={[3, null, 4]} gutterY={[3, 4]}>
                    {page.directors.map((director, index) => {
                      if (director.founder !== 'Yes') {
                        return (
                          <Staff
                            heading={director.director_name}
                            position={`Director`}
                            children={RichText.render(
                              director.director_bio,
                              linkResolver,
                              serializer
                            )}
                            image={
                              data.team.data.directors[index].director_image
                                ? data.team.data.directors[index].director_image
                                    .fluid
                                : ''
                            }
                          />
                        )
                      }
                    })}
                  </FlexGrid>
                </Box>
              </FlexGrid>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} pb={[4, 5]} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <Box maxWidth={640} mx="auto">
                <H2
                  children={RichText.asText(page.funders_header)}
                  mb="0.5em"
                  textAlign="center"
                />
                <H3
                  children={RichText.asText(page.funders_subheader)}
                  mb="0.25em"
                  textAlign="center"
                />
                <Box
                  children={RichText.render(
                    page.funders_body,
                    linkResolver,
                    serializer
                  )}
                  mb="4em"
                  textAlign="center"
                />
              </Box>
              <CustomList
                width={[1, 1 / 2, 1 / 3]}
                gutterX={[3, 4]}
                gutterY={[2, 3]}
              >
                {page.funders.map((funder, index) => (
                  <Funder
                    children={funder.funder_name}
                    href={funder.funders_link}
                  />
                ))}
              </CustomList>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} pb={[4, 5]} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <Box maxWidth={640} mx="auto">
                <H2
                  children={RichText.asText(page.volunteers_header)}
                  mb="0.5em"
                  textAlign="center"
                />
                <H3
                  children={RichText.asText(page.volunteers_subheader)}
                  mb="0.25em"
                  textAlign="center"
                />
                <Box
                  children={RichText.render(
                    page.volunteers_body,
                    linkResolver,
                    serializer
                  )}
                  mb="4em"
                  textAlign="center"
                />
              </Box>
              <FlexGrid
                justifyContent="center"
                gutterX={[2, 3, 4]}
                gutterY={[3, 4]}
              >
                {page.volunteers.map((volunteer, index) => (
                  <Volunteer
                    children={volunteer.volunteer_name}
                    image={
                      data.team.data.volunteers[index].volunteer_image
                        ? data.team.data.volunteers[index].volunteer_image.fluid
                        : ''
                    }
                  />
                ))}
              </FlexGrid>
            </Box>
          </Box>
        </>
      )
    }}
  />
)

export default Team
